<template>
<div class="mobile-page-layout">

    <div class="mobile-page-header">
        <div class="mobile-page-header-row">
            <div class="mobile-page-header-right">
                <router-link v-if="listShow" :to="{ name: 'HomeIntro'}" class="mobile-page-header-icon pointer back-icon">
                    <i class="header-uil-icon uil uil-angle-right-b"></i>
                </router-link>

                <div v-if="!listShow" class="mobile-page-header-icon pointer back-icon" @click.prevent="showSidebar('list')">
                    <i class="header-uil-icon uil uil-angle-right-b"></i>
                </div>

                <div class="mobile-page-header-icon">
                    <svg class="header-svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path class="fa-primary" d="M448 335.1c0 13.42-15.52 20.88-25.1 12.49L368 305.3l-54 43.2C303.5 356.8 288 349.4 288 335.1V96h160V335.1z" />
                        <path class="fa-secondary" d="M512 144v288c0 26.5-21.5 48-48 48h-416C21.5 480 0 458.5 0 432v-352C0 53.5 21.5 32 48 32h160l64 64H288v239.1c0 13.42 15.52 20.88 25.1 12.49L368 305.3l54 43.2C432.5 356.8 448 349.4 448 335.1V96h16C490.5 96 512 117.5 512 144z" />
                    </svg>
                </div>
                <div class="mobile-page-header-text">
                    <slot v-if="listShow">واچ لیست‌ها</slot>
                    <span v-if="!listShow && activeWatchList">{{ activeWatchList.title }}</span>
                </div>  
            </div>
            <div class="mobile-page-header-actions">

                <div v-if="listShow" class="mobile-page-header-icon pointer" @click="modal = !modal">
                    <i class="header-uil-icon uil uil-plus-circle"></i>
                </div>

                <div v-if="!listShow" class="mobile-page-header-icon pointer" @click.prevent="open_watch_list_add_item_modal()">
                    <i class="header-uil-icon uil uil-plus-circle"></i>
                </div>

                <div class="mobile-page-header-menu">
                    <template>
                        <div class="text-center">
                            <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="mobile-page-header-icon pointerv margin-0" v-bind="attrs" v-on="on">
                                    <i class="header-material-icon mdi mdi-dots-vertical"></i>
                                </div>
                            </template>
                            <v-list class="header-help-list">
                                <v-list-item @click.prevent="get_modal('search')" v-if="listShow">
                                    <v-list-item-title>جستجو</v-list-item-title>
                                </v-list-item>
                                
                                <v-list-item @click.prevent="get_modal('search2')" v-if="!listShow && activeWatchList.market_ids.length">
                                    <v-list-item-title>جستجو</v-list-item-title>
                                </v-list-item>
                                
                                <v-list-item @click.prevent="get_modal('help')">
                                    <v-list-item-title>راهنما</v-list-item-title>
                                </v-list-item>
                            </v-list>
                            </v-menu>
                        </div>
                    </template>                 
                </div>
            </div>
        </div>
    </div>

    <slot v-if="widgetLoad">
        <div class="tgju-widget-content full-padding">
            <div class="text-center p-2">
                <div class="widget-loading"></div>
            </div>
        </div>
    </slot>
    <slot v-else>
        <!-- صفحه فهرست ها -->
        <slot v-if="listShow">
            
            <div v-if="watchLists.length && search" class="mobile-search-box">
                <v-text-field class="mobile-app-input mobile-app-input-full" label="جستجو در فهرست‌ها" v-model="searchQuery"></v-text-field>
            </div>

            <div v-if="watchLists.length == 0" class="noWidget noContent">
                <div>
                    <i class="uil uil-list-ui-alt noContent-icon" aria-hidden="true"></i>
                    <p class="noContent-title">فهرستی برای نمایش وجود ندارد</p>
                    <div class="tgju-btn  m-top tgju-btn-primary" @click="modal = !modal"><i class="uil uil-plus-circle" style="display: inline-block;"></i> ایجاد فهرست</div>
                </div>
            </div>

            <table class="widget-table widget-table-lg" v-if="filteredList.length">
                <thead>
                    <tr>
                        <th>عنوان</th>
                        <th class="w60 text-center">ویرایش</th>
                        <th class="w60 text-center">حذف</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="pointer" v-for="(watchList, index) in filteredList" :key="index" >
                        <td class="border-l bg-gray" @click="openWatchList(watchList.id);"> {{ watchList.title }}</td>
                        <td class="w60 text-center border-l"><i class="uil uil-edit-alt table-icons pointer" @click="open_watch_list_edit_modal(watchList.id)"></i></td>
                        <td class="w60 text-center"><i class="uil uil-trash table-icons red-color pointer" @click="removeWatchList(watchList.id)"></i></td>
                    </tr>
                </tbody>
            </table>

            <!-- <div class="add-new-list-mobile">
                <div class="mobile-app-input-add btn-w100" @click="modal = !modal">افزودن فهرست جدید</div>
            </div> -->

            <v-overlay class="modal-overlay" :value="modal" @click="modal = false"></v-overlay>
            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="modal">
                        <v-sheet>
                            <div v-if="listShow" :class="'add-new-list-mobile in-modal ' + (addEditLoading ? 'loading-active' : '') ">
                                <v-text-field class="mobile-app-input" label="عنوان فهرست" id="newFavorite" v-model="newWatchList" @keyup.enter="addWatchList()"></v-text-field>
                                <div class="mobile-app-input-add" @click="addWatchList()">افزودن</div>
                            </div>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </slot>

        <!-- صفحه لینک ها -->
        <slot v-if="!listShow">


            <div v-if="activeWatchList.market_ids.length && search2" class="mobile-search-box">
                <v-text-field class="mobile-app-input mobile-app-input-full" label="جستجو در لیست‌ها" v-model="searchQueryList"></v-text-field>
            </div>

            <div v-if="activeWatchList.market_ids.length == 0" class="noWidget noContent">
                <div>
                    <i class="uil uil-list-ui-alt noContent-icon" aria-hidden="true"></i>
                    <p class="noContent-title">لیستی برای نمایش وجود ندارد</p>
                    <div class="tgju-btn  m-top tgju-btn-primary"  @click.prevent="open_watch_list_add_item_modal()"><i class="uil uil-plus-circle" style="display: inline-block;"></i> افزودن</div>
                </div>
            </div>

            <slot v-if="activeWatchList.market_ids.length">
                <div class="list-widget list-dash-items list-widget-structure" v-if="market_values && market_values.length">
                    <table class="widget-table widget-table-lg">
                        <thead>
                            <tr>
                                <th>عنوان</th>
                                <th class="text-center">آخرین قیمت</th>
                                <th class="hide-table-r text-center">بیشترین</th>
                                <th class="hide-table-r text-center">کمترین</th>
                                <th class="hide-420 text-center">تغییر</th>
                                <th class="hide-640 text-center">زمان</th>
                                <th class="w60 text-center">حذف</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="market_value in filteredListItems" :key="market_value.id">
                                <td class="border-l bg-gray">{{ market_value.title }}</td>
                                <td class="border-l text-center">{{ formatPrice(market_value.p) }}</td>
                                <td class="border-l text-center hide-table-r">{{ formatPrice(market_value.h) }}</td>
                                <td class="border-l text-center hide-table-r">{{ formatPrice(market_value.l) }}</td>
                                <td class="border-l text-center hide-420">
                                    <span v-if="market_value.dt == 'high' " class="high color-green inline-block dir margin-0">{{ formatPrice(market_value.d) }} ( {{ market_value.dp  }}% )</span>
                                    <span v-else-if="market_value.dt == 'low' " class="low color-red inline-block dir margin-0">{{ formatPrice(market_value.d) }} ( {{ market_value.dp  }}% )</span>
                                    <span v-else>{{ formatPrice(market_value.d) }} ( {{ market_value.dp  }}% )</span>
                                </td>
                                <td class="border-l text-center hide-640">{{ market_value.t }}</td>
                                <td class="w60 text-center" v-tooltip="'حذف'"><i class="uil uil-trash table-icons red-color pointer" @click.prevent="removeWatchListItemLink(market_value.item_id)"></i></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-center p-2" v-else>
                    <div class="widget-loading"></div>
                </div>
            </slot>
        </slot>
    </slot>

</div>
</template>

<style lang="scss">
    .high.dir,
    .low.dir {
        max-height: 25px;
        margin: 10px;
    }
</style>

<script>
// این کامپوننت اجزای  فهرست ها(واچ لیست) در داشبورد را نمایش می دهد
// import draggable from 'vuedraggable';
import Centrifuge from "centrifuge";
// import vSelect2 from 'vue-select'
import moment from "moment-jalaali";
// Vue.component('v-select2', vSelect2);

export default {
    name: 'WatchListMobile',
    props: ['widget', 'data', 'editable', 'wrapper'],
    components: {
        // draggable,
    },
    data() {
        return {
            modal: false,
            listShow: true,
            searchQuery: '',
            searchQueryList: '',
            watchLists: [],
            activeWatchList: {
                market_ids: []
            },
            market_values: undefined,
            newWatchList: undefined,
            responsive: {
                rightSection: true,
                leftSection: false,
                leftPanel: false,
            },
            widgetLoad: true,
            ListLoad: false,
            addEditLoading: false,
            search:false,
            search2:false
        }
    },
    computed: {
        current_route() {
            return this.$store.state.currentRoute;
        },
        // فیتلر سازی لیست های واچ لیست ها
        filteredList() {
            return this.watchLists.filter(watchList => {
                return watchList.title.toLowerCase().includes(this.searchQuery.toLowerCase())
            })
        },
        // فیلترسازی آیتم های لیست واچ لیست ها
        filteredListItems() {
            return this.market_values.filter(market_value => {
                return market_value.title.toLowerCase().includes(this.searchQueryList.toLowerCase())
            })
        },
    },
    mounted() {
        this.$watch('data', function (newValue, oldValue) {
            // بروز رسانی داده ها کامپونتت با لود مجدد
            if (Object.keys(newValue).length && (typeof oldValue == 'undefined' || !Object.keys(oldValue).length)) {
                // تزریق داده های ورود به کامپوننت
                this.loadData();
                // در صورتی که واچ لیستی وجود داشت به صورت پیش فرض انتخاب شود
                if (!isNaN(this.widget.id) && this.widget.data.selected_watchList > 0) {
                    this.openWatchList(this.widget.data.selected_watchList);
                } else {
                    this.getMarketValue();
                }
                // فعال کردن ارتباط سوکت برای بروزرسانی لحظه ای قیمت مارکت
                this.socketMarket();
            }
        }, { immediate: true });

        if(this.$helpers.runHelp('ShowHelp')){
            this.open_help_modal();
        }
    },
    methods: {
        get_modal(name){
            if(name == 'help'){
                this.open_help_modal();
            }else if(name == 'search'){
                this.search = !this.search;
            }else if(name == 'search2'){
                this.search2 = !this.search2;
            }
        },
        // این متد ارتباط با سوکت را برقرار میکند و سپس داده های موجود در واچ لیست را آپدیت میکند
        socketMarket() {
            var self = this;
            var centrifuge = new Centrifuge("wss://stream.tgju.org/connection/websocket");

            centrifuge.subscribe("tgju:stream", function (ctx) {
                for (var i in ctx.data) {
                    var item = ctx.data[i].split("|");
                    if (item[0] === 'market') {
                        for (const [index, market_item] of Object.entries(self.market_values)) {
                            if (self.activeWatchList.market_ids.length > 0) {
                                if (market_item.item_id == item[1]) {
                                    self.market_values[index].d = item[10];
                                    self.market_values[index].dp = item[11];
                                    self.market_values[index].dt = item[12];
                                    self.market_values[index].h = item[7];
                                    self.market_values[index].l = item[8];
                                    self.market_values[index].p = item[6];
                                    self.market_values[index].t = item[13];
                                }
                            }
                        }
                    }
                }
            });

            centrifuge.connect();
        },
        // این متد برای نمایش زمان می باشد
        time() {
            return moment().format('LTS');
        },
        // این متد برای نمایش و جدا سازی قیمت و نرخ ها از هم دیگر  می باشد
        formatPrice(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        // این تابع وظیفه تزریق داده های ورودی به کامپوننت را دارد
        loadData() {
            this.watchLists = this.data.watchLists;

            if (this.watchLists.length) {
                this.activeWatchList = this.watchLists[0];
            }

            this.widgetLoad = false;
        },
        // افزودن یک واچ لیست جدید
        addWatchList() {
            // در صورتی که عنوان خالی بود ادامه ندهد پروسه را
            if (!this.newWatchList) {
                this.$swal.fire({
                    icon: "error",
                    title: "لطفا عنوان واچ لیست را وارد نمایید",
                    showConfirmButton: !1,
                    timer: 2000
                });
            }

            if (!this.newWatchList) {
                return;
            }

            this.addEditLoading = true;

            let id = 'id-' + new Date().getTime();

            this.watchLists.push({
                id,
                title: this.newWatchList,
                market_ids: []
            });

            let api_data = {
                title: this.newWatchList,
                market_ids: null,
            };
            // ارسال درخواست به سرور جدید ذخیره سازی واچ لیست
            this.$helpers.makeRequest('POST', '/watchlist/save', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    let inserted_id = api_response.data.response.id;

                    this.watchLists.forEach((watchList, index) => {
                        if (watchList.id == id) {
                            watchList.id = inserted_id;
                        }
                    });
                    this.$swal.fire({
                        icon: "success",
                        title: "واچ لیست با موفقیت ایجاد شد",
                        showConfirmButton: !1,
                        timer: 2000
                    });
                    this.addEditLoading = false
                } else {
                    this.$swal.fire({
                        icon: "error",
                        title: "مشکلی در ایجاد واچ لیست رخ داده است",
                        showConfirmButton: !1,
                        timer: 2000
                    });
                    this.addEditLoading = false
                }
            });
            this.newWatchList = '';
            this.modal = false;
            // this.openWatchList(id);
        },
        // این متد جهت حذف واچ لیست استفاده میشود
        removeWatchList(id) {
            this.$helpers.confirm('هشدار حذف!', {
                message: 'آیا از حذف این مورد اطمینان دارید؟',
            }).then((confirmed) => {
                if (confirmed) {
                    this.activeWatchList = {
                        market_ids: []
                    };

                    let index = this.$helpers.getIndex(this.watchLists, id);

                    // حذف کردن واچ لیست از واچ لیست هایی فعلی در صفحه
                    this.watchLists.splice(index, 1);

                    let next_index = index - 1;
                    if (next_index < 0)
                        next_index = 0;

                    // در صورتی که واچ لیست دیگر وجود داشت به صورت اتوماتیک انتخاب شود 
                    if (this.watchLists.length > 0) {
                        this.openWatchList(this.watchLists[next_index].id);
                    }

                    // ارسال درخواست به سرور جهت حذف واچ لیست
                    this.$helpers.makeRequest('DELETE', '/watchlist/delete/' + id).then(api_response => {
                        this.$helpers.api_alert(api_response);
                        if (api_response.status == 200) {
                            this.$swal.fire({
                                icon: "success",
                                title: "واچ لیست با موفقیت حذف شد",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                            this.showSidebar();
                        } else {
                            this.$swal.fire({
                                icon: "error",
                                title: "مشکلی در حذف واچ لیست رخ داده است",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        }
                    });
                }
            });
        },
        // این متد مرتب سازی واچ لیست را هندل میکند
        saveWatchListsSort() {
            let sort = [];
            this.watchLists.forEach(watchList => {
                sort.push(watchList.id);
            });

            let api_data = {
                sort: JSON.stringify(sort),
            };

            this.$helpers.makeRequest('POST', '/watchlist/sort', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);
                if (api_response.status == 200) {
                    Vue.prototype.$toast.success({
                        title: 'ذخیره',
                        message: 'مرتب سازی با موفقیت ذخیره شد',
                    });
                } else {
                    Vue.prototype.$toast.warn({
                        title: 'ذخیره',
                        message: 'مشکلی در مرتب سازی رخ داده است',
                    });
                }
            });
        },
        // این متد وظیفه این را دارد پس از کلیک روی واچ لیست نرخ های آن را لود میکند
        openWatchList(id) {
            let index = this.$helpers.getIndex(this.watchLists, id);

            this.activeWatchList = this.watchLists[index];

            this.market_values = [];

            this.getMarketValue();
            this.listShow = false;
        },
        // این متد وظیفه هندل کردن باز کردن مودال جهت افزودن نماد جدید را دارد
        open_watch_list_add_item_modal() {
            // در صورتی که واچ لیستی وجود نداشت به پیام مناسب چاپ کند
            if (this.watchLists.length == 0) {
                Vue.prototype.$toast.warn({
                    title: 'توجه!',
                    message: 'شما باید حداقل یک واچ لیست ایجاد کرده باشید'
                });
                return;
            }

            // ایجاد یک کال بک یونیک
            let callbackEvent = this.$router.currentRoute.name + '_AddWatchList_' + new Date().getTime();

            // ست کردن تنظیمات مودال مورد نظر
            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'WatchListAdd.vue',
                config: {
                    title: 'افزودن نماد',
                    icon: 'uil uil-apps with-icon',
                    smallModal: true,
                    data: {},
                }
            };

            // باز کردن مودال
            this.$root.$emit('open_modal', modal_data);

            this.$root.$on(callbackEvent, (response) => {
                // هندل کال بک مودال
                if (response.action == 'addWatchList') {
                    let index = this.activeWatchList.market_ids.indexOf(response.data.market_id);
                    if (index < 0) {
                        this.activeWatchList.market_ids.push(response.data.market_id);
                        // درخواست ثبت و ذخیره آیتم واچ لیست
                        this.saveWatchListItem('addWatchList');
                        this.openWatchList(this.activeWatchList.id);
                    }
                }
            });
        },
        // این متد برای ویرایش عنوان واچ لیست است
        open_watch_list_edit_modal(id = 0) {
            if (id > 0) {
                let index = this.$helpers.getIndex(this.watchLists, id);
                this.activeWatchList = this.watchLists[index];
            }

            let callbackEvent = this.$router.currentRoute.name + '_EditWatchLists_' + new Date().getTime();

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'WatchListEdit.vue',
                config: {
                    title: 'ویرایش واچ لیست',
                    icon: 'uil uil-edit-alt with-icon',
                    smallModal: true,
                    data: {
                        watchList: this.$helpers.unbindObject(this.activeWatchList),
                    },
                }
            };

            this.$root.$emit('open_modal', modal_data);

            this.$root.$on(callbackEvent, (response) => {
                if (response.action == 'editWatchList') {
                    this.activeWatchList.title = response.data.title;

                    let api_data = {
                        title: this.activeWatchList.title,
                    };

                    this.$helpers.makeRequest('POST', '/watchlist/edit/' + this.activeWatchList.id, api_data).then(api_response => {
                        this.$helpers.api_alert(api_response);

                        if (api_response.status == 200) {
                            this.$swal.fire({
                                icon: "success",
                                title: "واچ لیست با موفقیت ویرایش شد",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        } else {
                            this.$swal.fire({
                                icon: "error",
                                title: "مشکلی در ویرایش واچ لیست رخ داده است",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        }
                    });
                }
            });
        },
        // این متد وظیفه دریافت نرخ اولیه شاخص ها را دارد
        getMarketValue() {
            if (this.activeWatchList.market_ids.length > 0) {
                let api_data = {
                    market_ids: this.activeWatchList.market_ids.join(),
                };
                this.$helpers.makeRequest('GET', '/watchlist/market', api_data).then(api_response => {
                    if (api_response.status == 200) {
                        this.market_values = api_response.data.response.detail;
                    } else {
                        Vue.prototype.$toast.warn({
                            title: 'خطا',
                            message: 'مشکلی در نمایش لیست رخ داده است',
                        });
                    }
                });
            }
        },
        // این متد وظیفه ارسال درخواست ذخیره ایتم های یک واچ لیست را برعهده دارد
        saveWatchListItem(action = null) {
            let api_data = {
                market_ids: JSON.stringify(this.$helpers.unbindObject(this.activeWatchList.market_ids)),
            };
            this.$helpers.makeRequest('POST', '/watchlist/market-ids-save/' + this.activeWatchList.id, api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    if (action == 'delete') {
                        this.$swal.fire({
                            icon: "success",
                            title: "شاخص با موفقیت حذف شد",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    } else if (action == 'addWatchList') {
                        this.$swal.fire({
                            icon: "success",
                            title: "شاخص با موفقیت افزوده شد",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    }
                } else {
                    if (action == 'delete') {
                        this.$swal.fire({
                            icon: "error",
                            title: "مشکلی در حذف شاخص رخ داده است",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    } else if (action == 'addWatchList') {
                        this.$swal.fire({
                            icon: "error",
                            title: "مشکلی در افزودن نماد رخ داده است",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    }
                }
            });
        },
        // این متد جهت باز کردن مودال تنظیمات واچ لیست را بر عهده دارد .
        open_settings_modal() {
            let callbackEvent = this.$router.currentRoute.name + '_save_settings_' + this.widget.id + '_' + new Date().getTime();

            let modal_data = {
                modal: 'widget_settings_modal',
                callbackEvent,
                component: 'Settings/WatchList.vue',
                config: {
                    data: {
                        title: this.widget.title,
                        watchLists: this.watchLists,
                        selected_watchList: this.widget.data.selected_watchList ? this.widget.data.selected_watchList : 0
                    }
                }
            };

            modal_data = this.$helpers.unbindObject(modal_data);

            this.$root.$emit('open_modal', modal_data);

            this.$root.$on(callbackEvent, (settings) => {
                this.widget.title = settings.title;
                this.widget.data = settings.data;

                if (this.widget.data.selected_watchList > 0) {
                    this.openWatchList(this.widget.data.selected_watchList);
                }

                let widget = this.$helpers.unbindObject(this.widget);

                this.$helpers.saveWidget(widget);
            });
        },
        // این متد برای حذف کردن آیتم های واچ لیست را بر عهده دارد .
        removeWatchListItemLink(market_id) {
            let index = this.activeWatchList.market_ids.indexOf(market_id);

            this.activeWatchList.market_ids.splice(index, 1);

            let index_market_values = this.market_values.findIndex(p => p.item_id == market_id);

            this.market_values.splice(index_market_values, 1);

            this.saveWatchListItem('delete');

        },
        makeResponsive(mode) {
            if (mode == 1) {
                this.responsive.rightSection = true;
                this.responsive.leftSection = false;
            } else if (mode == 2) {
                this.responsive.rightSection = false;
                this.responsive.leftSection = true;
            }
        },
        showSidebar() {
            this.listShow = true;
        },
        hideSidebar() {
            this.listShow = false;
        },
        // مودال راهنما
        open_help_modal() {
            let modal_data = {
                modal: 'widget_help_modal',
                component: 'Helps/WatchList.vue',
                title: this.widget.title,
                show: true,
            };
            this.$root.$emit('help_modal', modal_data);
        },
    },
}
</script>
